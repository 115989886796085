<template>
  <!-- 资金账号 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('zjgl_ziJinZhangHao:add')"
        class="itemBtn btnColor"
        @click="applybut"
        >账号申请</el-button
      >
      <el-button
        v-if="hasPermiss('zjgl_ziJinZhangHao:update')"
        class="itemBtn btnColor"
        @click="modifybut()"
        >修改</el-button
      >
      <el-button
        v-if="hasPermiss('zjgl_ziJinZhangHao:handel:close')"
        class="itemBtn btnColor"
        @click="withdraw()"
        >取消申请</el-button
      >
      <el-button
        v-if="hasPermiss('zjgl_ziJinZhangHao:handel:open')"
        class="itemBtn btnColor"
        @click="toExamine()"
        >启用</el-button
      >
      <el-button
        v-if="hasPermiss('zjgl_ziJinZhangHao:handel:close')"
        class="itemBtn btnColor"
        @click="CancelApproval()"
        >禁用</el-button
      >

      <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">账户名称:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="queryData.accountName"
                placeholder="请输入内容"
                @keyup.enter.native="queryBtn_ok()"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">账号状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.isShow"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok()"
              >
                <el-option
                  v-for="item in isShowList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">所属用户:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="queryData.ascriptionUserName"
                placeholder="请输入内容"
                @keyup.enter.native="queryBtn_ok()"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">仓库:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.storageId"
                filterable
                clearable
                placeholder="请选择"
                @change="queryBtn_ok()"
              >
                <el-option
                  v-for="item in cangkuList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="singleinp1">
            <div class="singleinpleft">新建时间:</div>
            <div class="singleinpright timebox">
              <el-date-picker
                value-format="yyyy-MM-dd"
                @change="timebut"
                v-model="time1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="item_left" v-if="hasPermiss('zjgl_ziJinZhangHao:query')">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询
            </el-button>
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="resetData()">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <!-- height  判断当表格超出某个高度的时候,固定高度,否则自动高度 具体的需要自己计算 -->
    <div class="tableBox">
      <el-table
        :height="tableHeigth"
        show-summary
        :summary-method="getSummaries"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            sortable
            :label="item.name"
            :prop="item.field"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- 排序 -->
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    ></sorttable>
    <!-- 分页组件 -->
    <paging
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="pageTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 上传 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 新增修改 -->
    <el-dialog
      :title="pringFrameTitle"
      :visible.sync="modify"
      :before-close="beforebut"
    >
      <div class="newForm">
        <el-form
          label-width="120px"
          :rules="rules"
          status-icon
          ref="newForm"
          :model="paymentArray"
          class="formBoxs"
        >
          <div class="queryItem1">
            <el-form-item label="所属用户:" prop="ascriptionUserId">
              <el-select
                v-model="paymentArray.ascriptionUserId"
                filterable
                clearable
              >
                <el-option
                  v-for="(item, index) in yonghuList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="应用类型:" prop="applicationStatus">
              <el-select
                filterable
                clearable
                placeholder="请选择"
                v-model="paymentArray.applicationStatus"
              >
                <el-option
                  v-for="(item, index) in yingyongList"
                  :key="index"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="账号类型:" prop="accountStatus">
              <el-select
                filterable
                clearable
                placeholder="请选择"
                v-model="paymentArray.accountStatus"
              >
                <el-option
                  v-for="(item, index) in zhanghuList"
                  :key="index"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="账号:" prop="account">
              <el-input
                placeholder="请输入"
                v-model="paymentArray.account"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="账户名称:" prop="accountName">
              <el-input
                placeholder="请输入"
                v-model="paymentArray.accountName"
              ></el-input>
            </el-form-item>
          </div>

          <div class="queryItem1">
            <el-form-item label="启用/禁用:" prop="isShow">
              <el-select
                filterable
                clearable
                placeholder="请选择"
                v-model="paymentArray.isShow"
              >
                <el-option
                  v-for="(item, index) in ExhibitionList"
                  :key="index"
                  :label="item.text"
                  :value="item.state"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="仓库:" prop="storageId">
              <el-select
                filterable
                clearable
                placeholder="请选择"
                v-model="paymentArray.storageId"
              >
                <el-option
                  v-for="(item, index) in cangkuList"
                  :key="index"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="排序:" prop="sort">
              <el-input-number
                v-model="paymentArray.sort"
                controls-position="right"
                :min="1"
                :max="1000"
              ></el-input-number>
            </el-form-item>
          </div>

          <div class="queryItem1">
            <el-form-item label="开户银行:">
              <el-input
                placeholder="请输入"
                v-model="paymentArray.bankName"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="开户行代码:">
              <el-input
                placeholder="请输入"
                v-model="paymentArray.bankCode"
              ></el-input>
            </el-form-item>
          </div>

          <div class="queryItem1">
            <el-form-item label="开户人:">
              <el-input
                placeholder="请输入"
                v-model="paymentArray.accountHolder"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="开户地址:">
              <el-input
                placeholder="请输入"
                v-model="paymentArray.openAddress"
              ></el-input>
            </el-form-item>
          </div>
          <div class="queryItem1">
            <el-form-item label="是否默认:">
              <el-select
                filterable
                clearable
                placeholder="请选择"
                v-model="paymentArray.isDefault"
              >
                <el-option
                  v-for="(item, index) in defaultList"
                  :key="index"
                  :label="item.text"
                  :value="item.state"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="queryItem1" style="width: 100%">
            <el-form-item label="备注:">
              <el-input
                type="textarea"
                v-model="paymentArray.comment"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <div style="margin-top: 10px">
          <div>上传图片(会员端收款二维码)</div>
          <uploadimgs
            @input="changeFileList"
            :fileNameLists="fileNameLists"
            :limit="6"
          ></uploadimgs>
        </div>
      </div>

      <div class="footerbox">
        <el-button class="" style="margin-right: 20px" @click="modify = false"
          >关闭</el-button
        >
        <el-button class="seniorbut" @click="payment">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";
import tool from "@/assets/js/tool";
import uploadimgs from "@/components/upLoadImgs.vue";
export default {
  inject: ["reload"],
  components: {
    draggable,
    paging,
    sorttable,
    uploadimgs,
  },
  data() {
    return {
      time1: "",
      rules: {
        storageId: [
          { required: true, message: "请选择仓库", trigger: "change" },
        ],
        account: [{ required: true, message: "请输入账户", trigger: "blur" }],
        accountName: [
          { required: true, message: "请输入账户名称", trigger: "blur" },
        ],
        ascriptionUserId: [
          { required: true, message: "请选择所属用户", trigger: "change" },
        ],
        applicationStatus: [
          { required: true, message: "请选择应用类型", trigger: "change" },
        ],
        accountStatus: [
          { required: true, message: "请选择账号类型", trigger: "change" },
        ],
        sort: [{ required: true, message: "请选择排序值", trigger: "change" }],
        isShow: [
          { required: true, message: "请选择是否展示", trigger: "change" },
        ],
      },
      paymentArray: {
        account: "", //账户
        bankCode: "",
        accountName: "", //账户名称
        accountStatus: "", //账户名称
        applicationStatus: "", //应用类型
        isShow: "", //是否展示0=否1=是
        sort: "", //排序
        storageId: "", //仓库id
        ascriptionUserId: "", //所属用户id
        comment: "", //备注
        isDefault: "", //是否默认0=否1=是
        openAddress: "", //开户地址
        accountHolder: "",
        bankName: "",
      },
      zhanghuList: [], //账户类型
      yingyongList: [], //应用类型
      defaultList: [
        {
          text: "是",
          state: 1,
        },
        {
          text: "否",
          state: 0,
        },
      ],
      ExhibitionList: [
        {
          text: "启用",
          state: 1,
        },
        {
          text: "禁用",
          state: 0,
        },
      ],
      form: {
        name: "",
        region: "",
      },
      modify: false, //弹窗
      pringFrameTitle: "账号申请", //弹框title
      pageType: "add", //弹窗状态
      form1: {
        name: "",
      },
      queryData: {
        accountName: "", //账户名称/银行名称
        ascriptionUserName: "", //所属用户名称
        createEndTime: "", //新建结束时间
        createStartTime: "", //新建开始时间
        isShow: "", //账户状态
        storageId: "", //仓库id
        pageTotal: 50, //一页几条
        pageNum: 1, //页码
      },
      isShowList: [
        {
          val: "0",
          name: "禁用",
        },
        {
          val: "1",
          name: "启用",
        },
      ],
      options: [],
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: "更多条件", //  输入框绑定动态class
      // ======公用组件==========
      pageNum: 1,
      total: 0,
      sizeList: [10, 20, 50, 100, 500],
      pageTotal: 50,
      show_upload: false, //显示弹窗
      show_sortTableHeard: false, //显示弹窗
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      tabelHeadeTitle: [
        {
          name: "账号",
          field: "account",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "账户名称",
          field: "accountName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "开户行代码",
          field: "bankCode",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "应用类型",
          field: "applicationStatusShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "所属用户",
          field: "ascriptionUserName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "是否默认",
          field: "isDefaultShow",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "是否启用",
          field: "isShowShow",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "开户人",
          field: "accountHolder",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "开户银行",
          field: "bankName",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "开户地址",
          field: "openAddress",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "备注",
          field: "comment",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
        {
          name: "新建时间",
          field: "createTime",
          width: "180",
          sort: 1,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      autoHeight: true, //自动高度
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "accountNumber", //页面标识 资金账号  (注意这里,每个列表定义一个唯一的标识,自定义,设置表头或者下载需要用到)
      currentItemArr: [], //选中的数据
      yonghuList: [], //用户列表
      cangkuList: [], //仓库列表

      fileNameLists: [],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    // 获取自定义排序表头
    this.getGroupFindFieldSort();
    // this.myTableHeard = this.tabelHeadeTitle;
    this.getData();
  },
  mounted() {
    let that = this;
    this.fetTableHeight();
    this.getStorageList(); //仓库列表
    this.adminUserList(); //管理员列表
    this.getStatusValList("finance_assetaccount_v1.account_status"); //账号类型
    this.getStatusValList("finance_assetaccount_v1.application_status"); //应用类型
  },
  watch: {},
  methods: {
    changeFileList(array) {
      this.fileNameLists = array;
    },
    //账号申请
    applybut() {
      this.modify = true;
      this.pringFrameTitle = "账号申请";
      this.pageType = "add";
    },
    //修改
    modifybut() {
      let currentItemArr = this.currentItemArr;
      if (currentItemArr.length != 1) {
        this.$message.warning("请勾选一条数据");
      } else {
        this.modify = true;
        this.pringFrameTitle = "修改";
        this.pageType = "modify1";
        Api.assetaccountDatail({
          assetaccountId: currentItemArr[0].assetaccountId,
        }).then((res) => {
          let paymentArray = res.data.result;
          delete paymentArray.accountStatusShow;
          delete paymentArray.isDefaultShow;
          delete paymentArray.isShowShow;
          delete paymentArray.storageName;
          delete paymentArray.applicationStatusShow;
          delete paymentArray.ascriptionUserName;
          this.paymentArray = paymentArray;
          console.log(this.paymentArray);

          let file = paymentArray.fileIds || [];
          this.fileNameLists = file.map((item) => {
            return {
              name: item.fileId,
              url: item.fileUrl,
            };
          });
        });
      }
    },
    //弹窗关闭
    beforebut() {
      this.$refs.newForm.resetFields();
      this.modify = false;
      this.paymentArray = {
        account: "", //账户
        bankCode: "",
        accountName: "", //账户名称
        accountStatus: "", //账户名称
        applicationStatus: "", //应用类型
        isShow: "", //是否展示0=否1=是
        sort: "", //排序
        storageId: "", //仓库id
        ascriptionUserId: "", //所属用户id
        comment: "", //备注
        isDefault: "", //是否默认0=否1=是
        openAddress: "", //开户地址
        accountHolder: "",
        bankName: "",
      };
      this.fileNameLists = [];
    },
    //确认
    payment() {
      this.$refs.newForm.validate((valid) => {
        if (valid == false) {
          return;
        } else {
          let _this = this;
          let arrs = this.fileNameLists.map((item) => {
            return item.name;
          });
          let paymentArray = {
            account: this.paymentArray.account,
            bankCode: this.paymentArray.bankCode,
            accountName: this.paymentArray.accountName,
            accountStatus: this.paymentArray.accountStatus,
            applicationStatus: this.paymentArray.applicationStatus,
            // assetaccountId: this.paymentArray.assetaccountId,
            isShow: this.paymentArray.isShow,
            sort: this.paymentArray.sort,
            storageId: this.paymentArray.storageId,
            ascriptionUserId: this.paymentArray.ascriptionUserId,
            comment: this.paymentArray.comment,
            isDefault: this.paymentArray.isDefault || 0,
            openAddress: this.paymentArray.openAddress,
            accountHolder: this.paymentArray.accountHolder,
            bankName: this.paymentArray.bankName,
            fileIds: arrs || [],
          };

          if (this.pageType == "add") {
            let sign = tool.getSign(paymentArray);
            paymentArray.sign = sign;
            Api.addAssetaccount(paymentArray).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                _this.getData();
                _this.beforebut();
              }
            });
          } else {
            paymentArray.assetaccountId = this.paymentArray.assetaccountId;
            let sign = tool.getSign(paymentArray);
            paymentArray.sign = sign;
            Api.updateAssetaccount(paymentArray).then((res) => {
              if (res.data.status == "success") {
                this.$message.success(res.data.message || "操作成功");
                _this.getData();
                _this.beforebut();
              }
            });
          }
        }
      });
    },
    // 获取列表数据
    getData() {
      let param = JSON.parse(JSON.stringify(this.queryData));
      param.pageStart = this.pageNum;
      param.pageTotal = this.pageTotal;
      Api.assetaccountList(param).then((res) => {
        let datas = res.data.result.data || [];
        this.className = res.data.result.className || "";
        this.tableData = datas;
      });
    },
    //获取账户类型
    getStatusValList(status) {
      Api.getStatusValList({ tableAndFieldName: status }).then((res) => {
        if (status == "finance_assetaccount_v1.account_status") {
          this.zhanghuList = res.data.result || [];
          console.log(this.zhanghuList);
        }
        if (status == "finance_assetaccount_v1.application_status") {
          this.yingyongList = res.data.result || [];
        }
      });
    },
    //获取仓库下拉列表
    getStorageList() {
      Api.getStorageList().then((res) => {
        let cangkuList = res.data.result || [];
        // console.log(cangkuList)
        this.cangkuList = cangkuList;
      });
    },
    //获取管理员列表
    adminUserList() {
      Api.adminUserList().then((res) => {
        this.yonghuList = res.data.result.data || [];
      });
    },
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      if (this.judge === false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },
    //审核
    toExamine() {
      let _this = this;
      let currentItemArr = this.currentItemArr;
      if (currentItemArr.length != 0) {
        let array = {
          assetaccountId: [],
          isShow: 1,
        };
        for (var i = 0; i < currentItemArr.length; i++) {
          array.assetaccountId.push(parseInt(currentItemArr[i].assetaccountId));
        }
        console.log(array.assetaccountId);
        let sign = tool.getSign(array);
        array.sign = sign;
        Api.handleIsShow(array).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            _this.getData();
          }
        });
      } else {
        this.$message.warning("请至少勾选一条数据");
      }
    },
    //取消审核
    CancelApproval() {
      let _this = this;
      let currentItemArr = this.currentItemArr;
      this.$confirm(
        "是否禁用勾选的账号,共" + currentItemArr.length + "个",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        if (currentItemArr.length != 0) {
          let array = {
            assetaccountId: [],
            isShow: 0,
          };
          for (var i = 0; i < currentItemArr.length; i++) {
            array.assetaccountId.push(
              parseInt(currentItemArr[i].assetaccountId)
            );
          }
          console.log(array.assetaccountId);
          let sign = tool.getSign(array);
          array.sign = sign;
          Api.handleIsShow(array).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              _this.getData();
            }
          });
        } else {
          this.$message.warning("请至少勾选一条数据");
        }
      });
    },
    //取消申请
    withdraw() {
      let currentItemArr = this.currentItemArr || [];
      if (currentItemArr.length < 1) {
        this.$message.warning("至少要选择一条数据");
        return;
      }
      this.$confirm("是否要取消已选的账户申请?", "提示:", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          let assetaccountIds = [];
          currentItemArr.forEach((item) => {
            assetaccountIds.push(item.assetaccountId);
          });

          let param = {
            assetaccountIds: assetaccountIds,
          };
          let sign = tools.getSign(param);
          param.sign = sign;
          Api.deleteAssetaccount(param).then((res) => {
            this.$message.success(res.data.message || "取消成功");
            this.getData();
          });
        })
        .catch(() => {});
    },
    //新建日期
    timebut(e) {
      this.queryData.createStartTime = e[0] + " " + "00:00:00";
      this.queryData.createEndTime = e[1] + " " + "00:00:00";
    },
    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },

    queryBtn_ok() {
      this.getData();
    },
    resetData() {
      this.queryData = {
        accountName: "", //账户名称/银行名称
        ascriptionUserName: "", //所属用户名称
        createEndTime: "", //新建结束时间
        createStartTime: "", //新建开始时间
        isShow: "", //账户状态
        storageId: "", //仓库id
        pageTotal: 50, //一页几条
        pageNum: 1, //页码
      };
      this.time1 = "";
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },

    // ===============定制表格和排序以及分页 ========

    // 设置自定义模板
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      //console.log(e);
      this.currentItemArr = e;
    },
    // 取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${val}`);
    },
    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight;
        if (this.activeName === "second") {
          queryHeigth = this.$refs.queryCriteria1.offsetHeight + 30; //查询框
        }
      } else {
        queryHeigth = 50;
        if (this.activeName === "second") {
          queryHeigth = 120;
        }
      }
      let pagsHeigth = 40; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60; //60是头部黑色部分
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */
    // ===============定制表格和排序以及分页 结束========

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      //console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "资金账户列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.newForm {
  // margin-left: 80px;
  .formBoxs {
    display: flex;
    flex-wrap: wrap;
  }
  .queryItem1 {
    width: 46%;
    margin-top: 20px;
  }
}
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}
.singleinp1 {
  min-width: 392px;
}
.el-input-number {
  height: 28px;
  width: 100%;
}
.el-input-number {
  line-height: 27px;
}

/deep/ .newForm .el-form-item__content {
  height: 28px !important;
  line-height: 28px !important;
  overflow: hidden;
}
</style>
